import {isNil} from 'ramda';

import {NestedValuesByKeyParams} from '../../../types/NestedValuesByKeyParams';
import {getAuditFieldById} from '../../../utils/getAuditFieldById';
import {getAuditFieldLabel} from '../../../utils/getAuditFieldLabel';
import {getAuditFieldValue} from '../../../utils/getAuditFieldValue';
import {getRelatedActionByComparisonKey} from '../../../utils/getRelatedActionByComparisonKey';
import {isCategoryEnabledInAudit} from '../../../utils/isCategoryEnabledInAudit';

export const getTechnicalCertificate = (params: NestedValuesByKeyParams) => {
  const relatedAction = getRelatedActionByComparisonKey(params.key, params.relatedActions);

  const isEnabledInAudit = isCategoryEnabledInAudit({
    categories: params.auditStructure?.categories ?? [],
    parentUniqueKey: 'VEHICLE_DOCUMENTATION',
    uniqueKey: 'REGISTRATION_CERTIFICATE',
  });

  if (isNil(relatedAction)) {
    return {isEnabledInAudit};
  }

  const content = relatedAction.values?.find(
    (v) => v.value === getAuditFieldById(relatedAction?.id, params.auditCategories)?.value
  )?.content;

  return {
    status: getAuditFieldLabel({
      key: params.key,
      relatedActions: params.relatedActions,
      auditCategories: params.auditCategories,
      language: params.language,
    }),
    numberOfPartsOfRegistrationCertificate: getAuditFieldValue({
      key: 'documentationNumberOfPartsOfRegistrationCertificate',
      relatedActions: content?.relatedActions,
      auditCategories: params.auditCategories,
    }),
    numberOfWwnersInTC: getAuditFieldValue({
      key: 'documentationNumberOfWwnersInTC',
      relatedActions: content?.relatedActions,
      auditCategories: params.auditCategories,
    }),
    countryOfFirstRegistration: getAuditFieldLabel({
      key: 'documentationCountryOfFirstRegistration',
      relatedActions: content?.relatedActions,
      auditCategories: params.auditCategories,
      language: params.language,
    }),
    comment: getAuditFieldValue({
      key: 'documentationTechnicalCertComment',
      relatedActions: content?.additionalInformation,
      auditCategories: params.auditCategories,
    }),
    isEnabledInAudit,
  };
};
