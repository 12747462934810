import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Documentation} from '../types/Documentation';
import {DocumentationParams} from './getDocumentationItemParams';

export const getIfShouldBeDocumentationHide = (
  documentation: Documentation,
  documentationItemParams: DocumentationParams | null
): boolean => {
  const documentationArray = [
    documentation?.vin?.value,
    documentation?.vin?.comment,
    documentation?.dateOfManufacture?.value,
    documentation?.firstRegistration?.value,
    documentation?.cocListStatus,
    documentation?.serviceBookType,
    documentation?.serviceBookState?.value,
    documentation?.serviceBookState?.comment,
    documentation?.currentMileage?.comment,
    documentation?.mandatoryEquipmentAndAccessories?.value,
    documentation?.mandatoryEquipmentAndAccessories?.comment,
  ];
  const operatingInstructionsIsSomeItemDefined =
    documentationItemParams?.operatingInstructions.attributes
      .map((item) => {
        if (item.value === '—') {
          return null;
        }
        return item?.value;
      })
      .some(isNotNilOrEmpty);

  const vehicleUnderFactoryWarrantyIsSomeItemDefined =
    documentationItemParams?.vehicleUnderFactoryWarranty
      .map((item) => {
        if (item.value === '—') {
          return null;
        }
        return item?.value;
      })
      .some(isNotNilOrEmpty);

  return (
    !operatingInstructionsIsSomeItemDefined &&
    !vehicleUnderFactoryWarrantyIsSomeItemDefined &&
    !documentationArray.some(isNotNilOrEmpty)
  );
};
