import {match, Pattern} from 'ts-pattern';

import {always, isNotNil} from 'ramda';

import {Nullish} from 'shared';

import {Category, CategoryUniqueKey} from '../api/digitalCertificateApi';

export type CategoryByUniqueKeyParams = {
  categories: Category[];
  parentUniqueKey: string;
  uniqueKey?: CategoryUniqueKey | Nullish;
};

export const getCategoryByUniqueKey = (params: CategoryByUniqueKeyParams) =>
  params.categories.find((category) =>
    match<[boolean, boolean, boolean, boolean, boolean], boolean>([
      category.visible,
      isNotNil(params.parentUniqueKey),
      isNotNil(params.uniqueKey),
      category.parentUniqueKey === params.parentUniqueKey,
      category.uniqueKey === params.uniqueKey,
    ])
      .with(
        [false, Pattern.boolean, Pattern.boolean, Pattern.boolean, Pattern.boolean],
        always(false)
      )
      .with([Pattern.any, false, false, Pattern.boolean, Pattern.boolean], always(false))
      .with([Pattern.any, true, true, true, false], always(false))
      .with([Pattern.any, true, true, false, true], always(false))
      .with([Pattern.any, true, true, true, true], always(true))
      .with([Pattern.any, true, false, false, Pattern.boolean], always(false))
      .with([Pattern.any, true, false, true, Pattern.boolean], always(true))
      .with([Pattern.any, false, true, Pattern.boolean, true], always(true))
      .with([Pattern.any, false, true, Pattern.boolean, false], always(false))
      .otherwise(always(false))
  ) ?? null;
