import {captureException} from '@sentry/browser';
import {CSSDimension, Integer, Box, VStack, Spinner, SpinnerSize} from 'platform/foundation';

import {Component, ReactNode} from 'react';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {EmptyStatus, EmptyStatusAction} from '../EmptyStatus/EmptyStatus';
import {ErrorStatus} from '../ErrorStatus/ErrorStatus';

type DataRenderFunction<T> = (params: {data: NonNullable<T>}) => ReactNode;

interface DataStatusBaseProps extends TestIdProps {
  spacing?: Integer;
  minHeight?: CSSDimension | Integer;
  grow?: number;
  isLoading?: boolean;
  isEmpty?: boolean;
  isError?: boolean;
  errorMessage?: string;
  emptyMessage?: string;
  emptySubheadline?: string;
  spinnerSize?: SpinnerSize;
  action?: EmptyStatusAction;
}

interface DataStatusRegularProps extends DataStatusBaseProps {
  children?: ReactNode;
  data?: never;
}

interface DataStatusWithDataProps<T> extends DataStatusBaseProps {
  data: T;
  children: DataRenderFunction<T>;
}

type DataStatusProps<T> = DataStatusRegularProps | DataStatusWithDataProps<T>;

interface DataStatusState {
  thrownError: Error | null;
}

export class DataStatus<T> extends Component<DataStatusProps<T>, DataStatusState> {
  constructor(props: DataStatusProps<T>) {
    super(props);
    this.state = {
      thrownError: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return {thrownError: error};
  }

  componentDidCatch(error: Error) {
    console.error('DataStatus error:', error);
    captureException(error);
  }

  private isWithNonNullableDataProps<T>(
    props: DataStatusProps<T> | DataStatusProps<NonNullable<T>>
  ): props is DataStatusWithDataProps<NonNullable<T>> {
    return 'data' in props && isNotNilOrEmpty(props.data);
  }

  private isRegularProps(props: DataStatusProps<T>): props is DataStatusRegularProps {
    return !('data' in props);
  }

  renderChildren() {
    if (this.isWithNonNullableDataProps(this.props)) {
      return this.props.children({data: this.props.data});
    }

    if (this.isRegularProps(this.props)) {
      return this.props.children;
    }

    return this.renderStatusContent();
  }

  renderStatusContent() {
    if (this.state.thrownError) {
      return (
        <ErrorStatus
          headline={this.state.thrownError.name}
          subheadline={this.state.thrownError.message}
          data-testid={suffixTestId('dataStatus-thrownError', this.props)}
        />
      );
    }

    if (this.props.isError) {
      return (
        <ErrorStatus
          headline={this.props.errorMessage}
          data-testid={suffixTestId('dataStatus-error', this.props)}
        />
      );
    }

    if (this.props.isLoading) {
      return (
        <Spinner
          size={this.props.spinnerSize}
          data-testid={suffixTestId('dataStatus-spinner', this.props)}
        />
      );
    }

    return (
      <EmptyStatus
        headline={this.props.emptyMessage}
        subheadline={this.props.emptySubheadline}
        action={this.props.action}
        data-testid={suffixTestId('dataStatus-empty', this.props)}
      />
    );
  }

  render() {
    if (
      !this.props.isError &&
      !this.props.isEmpty &&
      !this.props.isLoading &&
      !this.state.thrownError
    ) {
      return this.renderChildren();
    }

    return (
      <VStack
        minHeight={this.props.minHeight}
        justify="center"
        align="center"
        grow={this.props.grow}
        data-testid={suffixTestId('dataStatus-content', this.props)}
      >
        <Box
          data-testid={suffixTestId('dataStatus-content', this.props)}
          padding={this.props.spacing}
        >
          {this.renderStatusContent()}
        </Box>
      </VStack>
    );
  }
}
