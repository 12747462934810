import {z} from 'zod';

const countryCodeSchema = z.enum(['CZ', 'SK', 'PL', 'DE', 'AT', 'IT']);
export type CountryCode = z.infer<typeof countryCodeSchema>;

const marketSchema = z.enum([
  'CARVAGO_CZ',
  'CARVAGO_SK',
  'CARVAGO_PL',
  'CARVAGO_DE',
  'CARVAGO_AT',
  'CARVAGO_IT',
  'CARVAGO_XT',
]);
export type Market = z.infer<typeof marketSchema>;

export const localeSchema = z.enum(['CS', 'SK', 'PL', 'DE', 'AT', 'IT']);
export type Locale = z.infer<typeof localeSchema>;

const currencySchema = z.enum(['CZK', 'PLN', 'EUR']);
export type Currency = z.infer<typeof currencySchema>;

const powerUnitSchema = z.enum(['kw', 'hp']);
export type PowerUnit = z.infer<typeof powerUnitSchema>;

const listedCarsRequestBodySchema = z.object({
  color: z.array(z.string()).optional(),
  'fuel-type': z.array(z.string()).optional(),
  'mileage-from': z.number().optional(),
  'mileage-to': z.number().optional(),
  'power-from': z.number().optional(),
  'power-to': z.number().optional(),
  'power-unit': powerUnitSchema.optional(),
  'price-from': z.number().optional(),
  'price-to': z.number().optional(),
  'registration-date-from': z.number().optional(),
  'registration-date-to': z.number().optional(),
  transmission: z.array(z.string()).optional(),
});

const listedCarsRequestArgsSchema = z.object({
  country: countryCodeSchema.optional(),
  market: marketSchema.optional(),
  locale: localeSchema.optional(),
  currency: currencySchema.optional(),
  requestBody: listedCarsRequestBodySchema,
});
export type ListedCarsRequestArgs = z.infer<typeof listedCarsRequestArgsSchema>;

export type ListedCarsResponse = SimilarCar[];

const dateInfoSchema = z.object({
  date: z.string(),
  timezone_type: z.number(),
  timezone: z.string(),
});
export type DateInfo = z.infer<typeof dateInfoSchema>;

const countryInfoSchema = z.object({
  id: z.string(),
  const_key: z.string(),
  name: z.string(),
  iso_code: z.string(),
});
export type CountryInfo = z.infer<typeof countryInfoSchema>;

const sellerTypeSchema = z.object({
  id: z.string(),
  const_key: z.string(),
  name: z.string(),
});
export type SellerType = z.infer<typeof sellerTypeSchema>;

const sellerSchema = z.object({
  id: z.string(),
  type: sellerTypeSchema,
  country: countryInfoSchema,
  zip: z.string(),
  email: z.string().nullable(),
  rating_average: z.number().nullable(),
  rating_count: z.number().nullable(),
  offers_count: z.number(),
});
export type Seller = z.infer<typeof sellerSchema>;

const makeModelSchema = z.object({
  const_key: z.string(),
  label: z.string(),
});
export type MakeModel = z.infer<typeof makeModelSchema>;

const catalogFeatureSchema = z.object({
  const_key: z.string(),
  label: z.string(),
  seo_label: z.string().nullable(),
  category: z.string().nullable(),
  category_label: z.string().nullable(),
  parent: z.string().nullable(),
});
export type CatalogFeature = z.infer<typeof catalogFeatureSchema>;

const vehicleTagSchema = z.object({
  const_key: z.string(),
});
export type VehicleTag = z.infer<typeof vehicleTagSchema>;

const emissionInfoSchema = z.object({
  co2: z.number(),
  co2_class: z.string(),
  co2_cost_annual_high: z.number(),
  co2_cost_annual_low: z.number(),
  co2_cost_annual_medium: z.number(),
  co2_energy_cost_annual: z.number(),
  fuel_consumption: z.number(),
  vehicle_tax_de: z.number(),
});
export type EmissionInfo = z.infer<typeof emissionInfoSchema>;

const imageInfoSchema = z.object({
  id: z.number(),
  path: z.string(),
  ordering: z.number().optional(),
  ordering_computed: z.number().nullable().optional(),
});
export type ImageInfo = z.infer<typeof imageInfoSchema>;

const currencyInfoSchema = z.object({
  id: z.number().or(z.string()),
  name: z.string().optional(),
  const_key: z.string().optional(),
});
export type CurrencyInfo = z.infer<typeof currencyInfoSchema>;

const priceMarginDataSchema = z.object({
  price_with_margin: z.number(),
  margin_price: z.number(),
  margin_percent: z.number(),
  price_margin_fixed: z.boolean(),
});
export type PriceMarginData = z.infer<typeof priceMarginDataSchema>;

const priceDataSchema = z.object({
  price: z.number(),
  price_without_vat: z.number().nullable(),
  vat_rate: z.number().nullable(),
});
export type PriceData = z.infer<typeof priceDataSchema>;

const discountDataSchema = priceDataSchema.extend({
  is_discounted: z.boolean(),
  discount_date: z.string().nullable(),
  last_price_change: z.string().nullable(),
});
export type DiscountData = z.infer<typeof discountDataSchema>;

const priceInformationSchema = z.object({
  currency: currencyInfoSchema,
  vat_reclaimable: z.boolean(),
  margin_data: priceMarginDataSchema,
  nice_price_data: priceDataSchema,
  price_data: priceDataSchema,
  previous_price_data: priceDataSchema.nullable(),
  discount_data: discountDataSchema,
});
export type PriceInformation = z.infer<typeof priceInformationSchema>;

const priceScoreSchema = z.object({
  similar_cars_count: z.number(),
  uniform_price_percentiles: z.array(z.number()),
  uniform_price_percentile_level: z.number(),
  pp_level: z.number(),
});
export type PriceScore = z.infer<typeof priceScoreSchema>;

const similarCarSchema = z.object({
  id: z.string().nullable().optional(),
  slug: z.string().nullable().optional(),
  title: z.string().nullable().optional(),
  vin: z.string().nullable().optional(),
  hot: z.boolean().nullable().optional(),
  user: z.unknown().nullable().optional(),
  cubic_capacity: z.number().nullable().optional(),
  power: z.number().nullable().optional(),
  number_of_seats: z.number().nullable().optional(),
  fuel_consumption_urban: z.number().nullable().optional(),
  fuel_consumption_extra_urban: z.number().nullable().optional(),
  fuel_consumption_combined: z.number().nullable().optional(),
  fuel_consumption_unit: z.string().nullable().optional(),
  power_unit: z.string().nullable().optional(),
  weight: z.number().nullable().optional(),
  number_of_gears: z.number().nullable().optional(),
  carbon_dioxide_emission: z.number().nullable().optional(),
  equipment_score: z.number().nullable().optional(),
  manufactured_from: dateInfoSchema.nullable().optional(),
  manufactured_to: dateInfoSchema.nullable().optional(),
  number_of_airbags: z.number().nullable().optional(),
  number_of_cylinders: z.number().nullable().optional(),
  created_at: dateInfoSchema.nullable().optional(),
  updated_at: z.string().nullable().optional(),
  registration_date: z.string().nullable().optional(),
  mileage: z.number().nullable().optional(),
  number_of_owners: z.number().nullable().optional(),
  origin_country: z.string().nullable().optional(),
  last_registration_country: z.string().nullable().optional(),
  technical_inspection_valid_to: z.string().nullable().optional(),
  export_advantage: z.unknown().nullable().optional(),
  number_of_keys: z.number().nullable().optional(),
  warranty_mileage: z.number().nullable().optional(),
  warranty_date: z.string().nullable().optional(),
  source_created_at: z.string().nullable().optional(),
  manufacture_date: z.string().nullable().optional(),
  first_registration_country: z.string().nullable().optional(),
  former_country: z.string().nullable().optional(),
  wheel_diameter: z.number().nullable().optional(),
  registered: z.boolean().nullable().optional(),
  only_for_export: z.boolean().nullable().optional(),
  score: z.number().nullable().optional(),
  source_name: z.string().nullable().optional(),
  external_url: z.string().nullable().optional(),
  advertisement_score: z.number().nullable().optional(),
  first_crawl: dateInfoSchema.nullable().optional(),
  location_post_code: z.string().nullable().optional(),
  location_street: z.string().nullable().optional(),
  location_city: z.string().nullable().optional(),
  location_number: z.string().nullable().optional(),
  location_country: countryInfoSchema.nullable().optional(),
  status: z.string().nullable().optional(),
  external_score: z.number().nullable().optional(),
  suggested: z.boolean().nullable().optional(),
  seller: sellerSchema.nullable().optional(),
  hidden: z.boolean().nullable().optional(),
  cloned_advertised_car: z.unknown().nullable().optional(),
  in_preparation: z.boolean().nullable().optional(),
  sold_at: z.string().nullable().optional(),
  delisted_at: z.string().nullable().optional(),
  carvago_pickup_point: z.unknown().nullable().optional(),
  gefco_pickup_point: z.unknown().nullable().optional(),
  external_id: z.string().nullable().optional(),
  uniform_price: z.number().nullable().optional(),
  number_of_images: z.number().nullable().optional(),
  platform: z.unknown().nullable().optional(),
  advertising_rules: z
    .array(z.object({uuid: z.string()}))
    .nullable()
    .optional(),
  available_in_feed: z.boolean().nullable().optional(),
  available_in_feed_computed: z.boolean().nullable().optional(),
  make: makeModelSchema.nullable().optional(),
  model: makeModelSchema.nullable().optional(),
  catalog_features: z.array(catalogFeatureSchema).nullable().optional(),
  vehicle_class: z.string().nullable().optional(),
  vehicle_tags: z.array(vehicleTagSchema).nullable().optional(),
  labels: z.array(z.unknown()).nullable().optional(),
  emission: emissionInfoSchema.nullable().optional(),
  image: imageInfoSchema.nullable().optional(),
  images: z.array(imageInfoSchema).nullable().optional(),
  main_image: z.string().nullable().optional(),
  price_information: priceInformationSchema.nullable().optional(),
  price_score: priceScoreSchema.nullable().optional(),
  price: z.number().nullable().optional(),
  price_without_vat: z.number().nullable().optional(),
  total_price: z.number().nullable().optional(),
  vat_rate: z.number().nullable().optional(),
  exact_price: z.number().nullable().optional(),
  exact_price_without_vat: z.number().nullable().optional(),
  vat_reclaimable: z.boolean().nullable().optional(),
  price_currency: currencyInfoSchema.nullable().optional(),
  original_currency: currencyInfoSchema.nullable().optional(),
  power_hp: z.number().nullable().optional(),
});
export type SimilarCar = z.infer<typeof similarCarSchema>;
