import {AppLanguage} from 'platform/locale';

import {isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {AuditCategory, AuditStructure, RelatedAction} from '../../../api/digitalCertificateApi';
import {getAuditFieldById} from '../../../utils/getAuditFieldById';
import {getAuditFieldLabel} from '../../../utils/getAuditFieldLabel';
import {getRelatedActionByComparisonKey} from '../../../utils/getRelatedActionByComparisonKey';
import {isCategoryEnabledInAudit} from '../../../utils/isCategoryEnabledInAudit';

type ServiceBookStateParams = {
  relatedActions: RelatedAction[];
  auditCategories: AuditCategory[];
  language: AppLanguage;
  auditStructure: AuditStructure;
};

export const getServiceBookState = (params: ServiceBookStateParams) => {
  const relatedAction = getRelatedActionByComparisonKey(
    'documentationServiceBookType',
    params.relatedActions
  );

  const isEnabledInAudit = isCategoryEnabledInAudit({
    categories: params.auditStructure?.categories ?? [],
    parentUniqueKey: 'VEHICLE_DOCUMENTATION',
    uniqueKey: 'SERVICE_BOOK',
  });

  if (isNil(relatedAction)) {
    return {isEnabledInAudit};
  }

  const content = relatedAction.values?.find(
    (v) => v.value === getAuditFieldById(relatedAction?.id, params.auditCategories)?.value
  )?.content;

  const commentRelatedAction = content?.additionalInformation?.find(
    (additionalInformation) => additionalInformation.type === 'additional_comment'
  );

  const commentValue = getAuditFieldById(commentRelatedAction?.id, params.auditCategories)?.value;

  const comment = isNotNilOrEmpty(commentValue) && commentValue !== 'null' ? commentValue : null;

  return {
    value: getAuditFieldLabel({
      key: 'documentationServiceBookState',
      relatedActions: content?.relatedActions,
      auditCategories: params.auditCategories,
      language: params.language,
    }),
    comment,
    isEnabledInAudit,
  };
};
