import {
  Box,
  Center,
  HStack,
  Icon,
  Show,
  Text,
  ThemeIconKey,
  ThemeRadiusPath,
} from 'platform/foundation';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

type FlagSize = 'default' | 'small';

export interface FlagProps extends TestIdProps {
  text?: string;
  icon?: ThemeIconKey;
  size?: FlagSize;
  radius?: ThemeRadiusPath;
}

export function Flag(props: FlagProps) {
  const hasText = isNotNilOrEmpty(props.text);
  const isSmall = props.size === 'small';
  const height = isSmall ? 6 : 10;

  return (
    <Box
      height={height}
      paddingHorizontal={isSmall ? 2 : 4}
      width={hasText ? 'auto' : height}
      borderRadius={props.radius ?? 'medium'}
      backgroundColor="accent.base"
      data-testid={suffixTestId('flag', props)}
    >
      <Center height="100%">
        <HStack align="center" spacing={2}>
          <Icon value={props.icon} size={4} color="fill.white" />
          <Show when={hasText}>
            <Text size={isSmall ? 'xSmall' : 'small'} color="inverted">
              {props.text}
            </Text>
          </Show>
        </HStack>
      </Center>
    </Box>
  );
}
