import {Tooltip, useEventListener} from 'platform/components';
import {Box, HStack, Show, useWindowDimensions} from 'platform/foundation';

import {useRef, useState} from 'react';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

import {CatalogFeature} from '../../../api/types/carvagoApiTypes';
import {Button} from '../../../components/Button/Button';
import {Flag} from '../../../components/Flag/Flag';
import i18n from '../../../i18n/i18n';

const SHOWN_ROWS = 1;
const EXPECTED_MAX_FEATURES_PER_ROW = 5;
const EXPAND_BUTTON_WIDTH = 49;

interface FeatureFlagsProps extends TestIdProps {
  features: CatalogFeature[];
}

export function FeatureFlags(props: FeatureFlagsProps) {
  const features = props.features
    .filter((feature) => feature.const_key.includes('FEATURE_'))
    .sort((a, b) => a.label.localeCompare(b.label));

  const [isExpanded, _, collapse, toggleExpanded] = useBoolean(false);
  const [featuresVisibleCount, setFeaturesVisibleCount] = useState(features.length);
  const lastVisibleElement = useRef<HTMLDivElement>(null);
  const lastVisibleElementIndex = useRef<number>(null);
  const {width: windowWidth} = useWindowDimensions();

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleExpanded();
  };

  const calculateVisibleElement = (ref: HTMLDivElement | null, index: number) => {
    if (ref) {
      const rect = ref.getBoundingClientRect();
      const parentRect = ref.parentElement?.getBoundingClientRect();

      const row = Math.floor((rect.top - (parentRect?.top ?? 0)) / rect.height) + 1;

      if (row === SHOWN_ROWS) {
        lastVisibleElement.current = ref;
        lastVisibleElementIndex.current = index;
      }

      if (row === SHOWN_ROWS + 1) {
        const lastRect = lastVisibleElement.current?.getBoundingClientRect();
        const rightSpace = lastRect && parentRect ? parentRect.right - lastRect.right : 0;

        setFeaturesVisibleCount(
          (lastVisibleElementIndex.current ?? 0) - (rightSpace < EXPAND_BUTTON_WIDTH ? 1 : 0) + 1
        );
      }
    }
  };

  const visibleFeatures = isExpanded ? features : features.slice(0, featuresVisibleCount);
  const hiddenItemsCount = features.length - featuresVisibleCount;
  const hasMoreItems = hiddenItemsCount > 0;

  useEventListener(window, 'resize', () => {
    collapse();
  });

  return (
    <>
      <Box height={0} overflow="hidden" key={`calculator-${windowWidth}`}>
        <HStack spacing={2} wrap>
          {features.slice(0, SHOWN_ROWS * EXPECTED_MAX_FEATURES_PER_ROW).map((feature, index) => (
            <div key={feature.const_key + index} ref={(ref) => calculateVisibleElement(ref, index)}>
              <Flag text={feature.label} size="small" radius="xSmall" />
            </div>
          ))}
        </HStack>
      </Box>
      <HStack spacing={2} wrap>
        {visibleFeatures.map((feature, index) => (
          <Flag key={feature.const_key + index} text={feature.label} size="small" radius="xSmall" />
        ))}

        <Show onDesktop onNotebook>
          <Tooltip
            hasAutoWidth
            label={
              <Box padding={2}>
                <HStack spacing={2} wrap justify="center">
                  {features.slice(visibleFeatures.length, features.length).map((feature, index) => (
                    <Flag
                      key={feature.const_key + index}
                      text={feature.label}
                      size="small"
                      radius="xSmall"
                    />
                  ))}
                </HStack>
              </Box>
            }
            placement="top"
          >
            <Flag text={`${hiddenItemsCount} +`} size="small" radius="xSmall" />
          </Tooltip>
        </Show>

        <Show onMobile onTablet>
          <Show when={!isExpanded && hasMoreItems}>
            <Button
              size="small"
              variant="primary"
              radius="xSmall"
              title={`${hiddenItemsCount} +`}
              aria-label={`Show ${hiddenItemsCount} more features`}
              onClick={handleButtonClick}
              data-testid={suffixTestId('expandButton', props)}
            />
          </Show>

          <Show when={isExpanded && hasMoreItems}>
            <Button
              size="small"
              variant="outline"
              radius="xSmall"
              title={i18n.t('hide')}
              onClick={handleButtonClick}
              data-testid={suffixTestId('collapseButton', props)}
            />
          </Show>
        </Show>
      </HStack>
    </>
  );
}
