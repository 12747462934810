import {isNotNil} from 'ramda-adjunct';

import {CategoryByUniqueKeyParams, getCategoryByUniqueKey} from './getCategoryByUniqueKey';

interface IsEnabledInAuditParams extends CategoryByUniqueKeyParams {}

/**
 * Check if the category is enabled in the audit (inspection) setting in dms.
 * This can be used to check if fields should be shown in the UI.
 */
export function isCategoryEnabledInAudit(params: IsEnabledInAuditParams) {
  const category = getCategoryByUniqueKey(params);

  // When category is not present in auditStructure, it's disabled in the audit (inspection) setting in dms.
  return isNotNil(category);
}
