import {environment} from '@digital-certificate/environment';

import {isNotEmpty, isNil} from 'ramda';

import {buildArray} from 'shared';

import {POWER_RANGE} from '../consts/POWER_RANGE';
import {YEAR_RANGE} from '../consts/YEAR_RANGE';
import {VehicleData} from '../types/VehicleData';
import {getYearFromDateString} from './getYearFromDateString';

const CARVAGO_URL = environment.CARVAGO_URL ?? '';

export const getCarvagoUrl = (vehicleData: VehicleData) => {
  const yearDateString = vehicleData?.firstRegistrationOn ?? vehicleData?.manufacturedOn;
  const year = yearDateString ? getYearFromDateString(yearDateString) : undefined;
  const power = vehicleData?.power;

  const parameters = buildArray()
    .whenNot(isNil(vehicleData?.modelFamily), `model=${vehicleData?.modelFamily}`)
    .whenNot(
      isNil(year),
      `registration-date-from=${Number(year) - YEAR_RANGE}&registration-date-to=${Number(year) + YEAR_RANGE}`
    )
    .whenNot(isNil(vehicleData?.fuelType), `fuel-type=${vehicleData?.fuelType}`)
    .whenNot(
      isNil(power),
      `power-from=${Math.max(0, Number(power) - POWER_RANGE)}&power-to=${Number(power) + POWER_RANGE}&power-unit=kw`
    )
    .whenNot(isNil(vehicleData?.transmission), `transmission=${vehicleData?.transmission}`);

  const link = isNotEmpty(parameters) ? `${CARVAGO_URL}cars?${parameters.join('&')}` : CARVAGO_URL;

  return link;
};
