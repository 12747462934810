import {environment} from '@digital-certificate/environment';
import {DataStatus, Separator} from 'platform/components';
import {Box, Clickable, Grid, Heading, Image, VStack} from 'platform/foundation';
import {AppLanguage} from 'platform/locale';
import styled from 'styled-components';

import {useGetListedCarsQuery} from '../../api/carvagoApi';
import {Currency} from '../../api/types/carvagoApiTypes';
import {Section} from '../../components/Section/Section';
import {languageToCurrencyMap} from '../../consts/languageToCurrencyMap';
import i18n from '../../i18n/i18n';
import {CarParameters} from './components/CarParameters';
import {FeatureFlags} from './components/FeatureFlags';
import {MoreSimilarCarsCard} from './components/MoreSimilarCarsCard';
import {PriceRow} from './components/PriceRow';
import {VehicleData} from './types/VehicleData';
import {getCarvagoUrl} from './utils/getCarvagoUrl';
import {getFilters} from './utils/getFilters';
import {isLocale} from './utils/isLocale';
import {mergeAndSliceArrays} from './utils/mergeAndSliceArrays';

const FALLBACK_CURRENCY = 'EUR' as const satisfies Currency;

interface SimilarCarsProps {
  vehicleData: VehicleData;
}

export function SimilarCars(props: SimilarCarsProps) {
  const headers = {
    locale: isLocale(i18n.language) ? i18n.language : undefined,
    currency: languageToCurrencyMap[i18n.language as AppLanguage] ?? FALLBACK_CURRENCY,
  };

  const {relaxedFilters, strictFilters} = getFilters(props.vehicleData);

  const {
    data: strictData,
    isLoading: isStrictLoading,
    isError: isStrictError,
  } = useGetListedCarsQuery({...headers, requestBody: strictFilters});

  const isStrictData = strictData && strictData.length > 0;
  const hasEnoughStrictData = isStrictData && strictData.length >= 3;
  const skipRelaxed = isStrictLoading || isStrictError || (isStrictData && hasEnoughStrictData);
  const {
    data: relaxedData,
    isLoading: isRelaxedLoading,
    isError: isRelaxedError,
  } = useGetListedCarsQuery({...headers, requestBody: relaxedFilters}, {skip: skipRelaxed});

  const isLoading = isStrictLoading || isRelaxedLoading;
  const isError = isStrictError || isRelaxedError;
  const data = mergeAndSliceArrays(strictData, relaxedData, 3);

  if (isError || data?.length === 0) {
    return null;
  }

  return (
    <Section
      id="SIMILAR_CARS"
      heading={i18n.t('similarCars.title')}
      content={
        <DataStatus
          isLoading={isLoading}
          isEmpty={data?.length === 0}
          emptyMessage={i18n.t('similarCars.noCars')}
          minHeight={82}
        >
          <Grid columns={[1, 2, 4]} align="stretch" spacing={6}>
            {data?.map((car) => (
              <Clickable
                key={car.id}
                role="link"
                onClick={() =>
                  window.open(
                    `${environment.CARVAGO_URL}car/${car.id}/${car.slug}`,
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
                aria-label={`View details for ${car.title || 'car'}`}
              >
                <StyledCard>
                  <VStack height="100%">
                    <Image
                      src={car.image?.path}
                      alt={car.title ?? 'Similar car'}
                      ratio="3/2"
                      fit="cover"
                    />
                    <Box flexGrow={1} padding={4}>
                      <VStack justify="space-between" height="100%" spacing={3}>
                        <VStack spacing={3}>
                          <Heading size={4}>{car.title}</Heading>
                          <CarParameters car={car} />
                        </VStack>
                        <VStack spacing={3}>
                          <FeatureFlags features={car.catalog_features ?? []} />
                          <Separator spacing={0} />
                          <PriceRow car={car} />
                        </VStack>
                      </VStack>
                    </Box>
                  </VStack>
                </StyledCard>
              </Clickable>
            ))}
            <MoreSimilarCarsCard link={getCarvagoUrl(props.vehicleData)} />
          </Grid>
        </DataStatus>
      }
    ></Section>
  );
}

const StyledCard = styled.div`
  border: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  border-radius: ${({theme}) => theme.radii.small};
  overflow: hidden;
  height: 100%;
  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[20][100]};
  }
`;
