import {isNil} from 'ramda';

import {NestedValuesByKeyParams} from '../../../types/NestedValuesByKeyParams';
import {getAuditFieldById} from '../../../utils/getAuditFieldById';
import {getAuditFieldLabel} from '../../../utils/getAuditFieldLabel';
import {getAuditFieldValue} from '../../../utils/getAuditFieldValue';
import {getAuditFieldValueWithUnit} from '../../../utils/getAuditFieldValueWithUnit';
import {getRelatedActionByComparisonKey} from '../../../utils/getRelatedActionByComparisonKey';
import {isCategoryEnabledInAudit} from '../../../utils/isCategoryEnabledInAudit';

export const getVehicleUnderFactoryWarranty = (params: NestedValuesByKeyParams) => {
  const relatedAction = getRelatedActionByComparisonKey(params.key, params.relatedActions);

  const isEnabledInAudit = isCategoryEnabledInAudit({
    categories: params.auditStructure?.categories ?? [],
    parentUniqueKey: 'VEHICLE_DOCUMENTATION',
    uniqueKey: 'VEHICLE_UNDER_FACTORY_WARRANTY',
  });

  if (isNil(relatedAction)) {
    return {isEnabledInAudit};
  }

  const content = relatedAction.values?.find(
    (v) => v.value === getAuditFieldById(relatedAction?.id, params.auditCategories)?.value
  )?.content;

  return {
    status: getAuditFieldLabel({
      key: params.key,
      relatedActions: params.relatedActions,
      auditCategories: params.auditCategories,
      language: params.language,
    }),
    starDate: getAuditFieldValue({
      key: 'documentationVehicleUnderFactoryWarrantyStarDate',
      relatedActions: content?.relatedActions,
      auditCategories: params.auditCategories,
    }),
    endDate: getAuditFieldValue({
      key: 'documentationVehicleUnderFactoryWarrantyEndDate',
      relatedActions: content?.relatedActions,
      auditCategories: params.auditCategories,
    }),
    raidUntilEndOfWarranty: getAuditFieldValueWithUnit({
      key: 'documentationRaidUntilEndOfWarranty',
      relatedActions: content?.relatedActions,
      auditCategories: params.auditCategories,
    }),
    isEnabledInAudit,
  };
};
