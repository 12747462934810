import {ReactNode} from 'react';

import {Theme, useGetThemeQuery} from '../api/digitalCertificateApi';
import {themeIcons} from '../assets/icons/themeIcons';
import {getWorkspaceCustomization} from '../utils/getWorkspaceCustomization';
import {useThemeId} from './useThemeId';

interface UseThemeConfig {
  theme: Theme;
  isLoading: boolean;
  logo: string | null | ReactNode;
  brandLogo: string | null | ReactNode;
}

export function useThemeConfig(): UseThemeConfig {
  const themeId = useThemeId();

  const theme = useGetThemeQuery(
    {themeId},
    {
      skip: !themeId,
      refetchOnMountOrArgChange: false,
    }
  );

  const workspace = getWorkspaceCustomization();

  const workspaceThemeConfig = {
    theme: workspace.theme,
    logo: workspace.logo,
    isLoading: false,
    brandLogo: workspace.brandLogo?.src,
  };

  if (!themeId) {
    return workspaceThemeConfig;
  }

  if (theme.isLoading) {
    return {theme: workspace.theme, isLoading: true, logo: null, brandLogo: null};
  }

  const externalTheme = theme.data?.theme;

  if (externalTheme) {
    return {
      theme: {
        ...externalTheme,
        // Theme requires icons. Those are not currently part of the theme data.
        // All workspace themes have the same icons (which are React components).
        icons: themeIcons,
      },
      logo: theme.data?.logo.url,
      // `theme` (from the API) currently does not have brand logo, it's statically configured in the code in getWorkspaceCustomization
      brandLogo: workspaceThemeConfig.brandLogo,
      isLoading: false,
    };
  }

  return workspaceThemeConfig;
}
