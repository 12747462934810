import {useCallback, useRef} from 'react';

import {BaseFunction} from '../types/BaseFunction';
import {Nullish} from '../types/Nullish';
import {isNotNilOrZero} from '../utils/isNotNilOrZero';

/**
 * Custom hook for creating a debounced callback function.
 *
 * @param {BaseFunction} callback - The callback function to be debounced.
 * @param {number} delay - The delay in milliseconds for to debounce.
 * @param {number} [maxWait=0] - The maximum time to wait before invoking the function, regardless of the delay.
 *
 * @returns {BaseFunction} - The debounced callback function.
 *
 * @example
 * // Import the hook
 * import { useDebouncedCallback } from 'path-to-file';
 *
 * // Usage inside a functional component
 * function MyComponent() {
 *   const click = useCallback((param) => {
 *     // Your callback logic here
 *     console.log(param);
 *   }, []);
 *
 *   // Set the desired debounce delay in milliseconds
 *   const debouncedClick = useDebouncedCallback(click, 300);
 *
 *   return (
 *     <div>
 *       <button onClick={debouncedClick}>Click me (Debounced)</button>
 *     </div>
 *   );
 * };
 */
export function useDebouncedCallback<T extends BaseFunction>(
  callback: T,
  delay: number,
  maxWait?: number | Nullish
): T {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const maxTimerRef = useRef<NodeJS.Timeout | null>(null);

  return useCallback(
    (...args: Parameters<T>) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        if (maxTimerRef.current) {
          clearTimeout(maxTimerRef.current);
          maxTimerRef.current = null;
        }
        callback(...args);
      }, delay);

      if (isNotNilOrZero(maxWait) && !maxTimerRef.current) {
        maxTimerRef.current = setTimeout(() => {
          if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
          }
          maxTimerRef.current = null;
          callback(...args);
        }, maxWait!);
      }
    },
    [callback, delay, maxWait]
  ) as T;
}
