import {Box, Grid, Heading, Show, Stack, Text, VStack} from 'platform/foundation';

import {isFalsy, isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {Comment} from '../Comment/CommentExpandable';
import {CommentExpandable} from '../CommentExpandable/CommentExpandable';

export interface DocumentationAttribute {
  label: string;
  value: string | Nullish;
}

interface DocumentationItemProps extends TestIdProps {
  heading: string;
  comment?: string | Nullish;
  attributes: DocumentationAttribute[];
  isPrintView?: boolean;
}

export function DocumentationItem(props: DocumentationItemProps) {
  return (
    <Stack
      data-testid={suffixTestId('documentationItem', props)}
      direction={['column', 'row', 'row', 'row']}
      spacing={[3, 6, 10, 10]}
      align="flex-start"
    >
      <Box flex={1} maxWidth={100} width="100%">
        <Heading size={2}>{props.heading}</Heading>
      </Box>
      <Box flex={1} width="100%">
        <VStack spacing={[3, 6, 6, 10]}>
          <Grid columns={[1, 1, 2, 3]} spacing={[3, 6, 6, 10]}>
            {props.attributes.map((attribute) => (
              <VStack key={attribute.label}>
                <Heading size={3} alternative>
                  {attribute.value}
                </Heading>
                <Text size="small" color="tertiary">
                  {attribute.label}
                </Text>
              </VStack>
            ))}
          </Grid>

          <Show when={isFalsy(props.isPrintView) && isNotNilOrEmpty(props.comment)}>
            <CommentExpandable comment={props.comment} />
          </Show>
          <Show when={isTrue(props.isPrintView) && isNotNilOrEmpty(props.comment)}>
            <Comment comment={props.comment} />
          </Show>
        </VStack>
      </Box>
    </Stack>
  );
}
