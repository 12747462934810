import {getValueByDevice, Image, useDevice} from 'platform/foundation';

import {useThemeConfig} from '../../../hooks/useThemeConfig';
import {getWorkspaceFromUri} from '../../../utils/getWorkspaceFromUri';

const {workspace} = getWorkspaceFromUri();

const MOBILE_HEIGHT = 8;
const DEFAULT_HEIGHT = 20;

export function Logo() {
  const {logo: applicationLogo} = useThemeConfig();
  const device = useDevice();
  const height = getValueByDevice(device, MOBILE_HEIGHT, DEFAULT_HEIGHT);

  if (typeof applicationLogo === 'string') {
    return (
      <Image
        maxHeight={height}
        height={height}
        fit="contain"
        src={applicationLogo}
        alt={`${workspace} logo`}
      />
    );
  }

  return applicationLogo;
}
