import {HStack, Text, Show, Heading} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {isNotNil} from 'ramda';

import {SimilarCar} from '../../../api/types/carvagoApiTypes';
import {Flag} from '../../../components/Flag/Flag';

interface PriceRowProps {
  car: SimilarCar;
}

export function PriceRow(props: PriceRowProps) {
  const formatCurrency = useFormatCurrency();

  return (
    <HStack justify="space-between" align="center">
      <Text size="xSmall">Cena</Text>
      <HStack spacing={1} align="center">
        <Show when={props.car.vat_reclaimable}>
          <Flag text="VAT" size="small" radius="xSmall" />
        </Show>
        <Heading size={4}>
          {isNotNil(props.car.price) && isNotNil(props.car.price_currency?.name)
            ? formatCurrency(props.car.price, props.car.price_currency?.name)
            : null}
        </Heading>
      </HStack>
    </HStack>
  );
}
