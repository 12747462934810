import {captureException} from '@sentry/browser';
import flagsmith from 'flagsmith';
import {ITraits} from 'flagsmith/types';

import {useCallback, useEffect, useRef} from 'react';

import {isNil, isNotNil} from 'ramda';

import {browserStorageKey} from '@dms/config';
import {environment} from '@dms/environment';

import {useBoolean} from 'shared';

interface UseFlagSmithProps {
  environmentID: string;
  identity?: string;
  apiUrl: string;
  skip?: boolean;
  traits?: ITraits;
}

const isDev = environment.ENV_TYPE === 'dev';

export function useFlagsmith({environmentID, identity, apiUrl, skip, traits}: UseFlagSmithProps) {
  const [isFlagSmithInitialized, setAsInitialized, setAsNotInitialized] = useBoolean(false);

  // This is used to check if the feature flags are loaded from the API
  // and not from the local storage cache. Cached flags may not be up to date.
  const [areFeatureFlagsLoadedFromApi, setAsLoadedFromApi] = useBoolean(false);

  const isDevSettingsMockingFlagsmithFlags =
    isDev && isNotNil(localStorage.getItem(browserStorageKey.MOCK_FLAGS_KEY));

  const handleInitFlagsmith = useCallback(
    (userIdentity?: string, newTraits?: ITraits) => {
      flagsmith
        .init({
          environmentID,
          api: apiUrl,
          identity: userIdentity,
          preventFetch: isDevSettingsMockingFlagsmithFlags,
          cacheFlags: true,
          traits: newTraits,
          onChange: (_changes, params) => {
            if (!!params.isFromServer) {
              setAsLoadedFromApi();
            }
          },
        })
        .then(setAsInitialized)
        .catch((error: Error) => {
          // Flagsmith sometimes throws an error with message 'No error message' without any other information
          if (error?.message !== 'No error message' || isNil(error?.message)) {
            console.error('Error when initializing Flagsmith:', error);
            captureException(error);
          }
          setAsNotInitialized();
        });
    },
    [environmentID, apiUrl]
  );

  useOnMount(() => {
    if (skip) {
      return;
    }
    handleInitFlagsmith(identity, traits);
  });

  return {isFlagSmithInitialized, handleInitFlagsmith, areFeatureFlagsLoadedFromApi};
}

const useOnMount = (func?: () => void) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current === false) {
      func?.();
      didMountRef.current = true;
    }

    return () => {
      didMountRef.current = false;
    };
  }, []);
};
