import {HStack, Image, Box} from 'platform/foundation';

import {DEFAULT_LOGO_PATH} from '../../consts/defaultLogo';
import {getWorkspaceCustomization} from '../../utils/getWorkspaceCustomization';

/**
 * Initial HTML document (apps/digital-certificate/src/index.html)
 * contains duplicate of this component.
 * Always check if this component is identical
 * to the one in the initial html.
 */
export function Loading() {
  const workspace = getWorkspaceCustomization();

  const workspaceLoadingLogo = workspace.isBrandLogoOnInitialLoad ? workspace.brandLogo?.src : null;
  const src = workspaceLoadingLogo ?? DEFAULT_LOGO_PATH;

  return (
    <HStack align="center" justify="center" minHeight="100vh" minWidth="100vw">
      <Box padding={3}>
        <HStack spacing={4} align="center" justify="center">
          <Image src={src} alt="Digital Certificate" height={12} width="auto" />
          {/* 
          eslint-disable-next-line react/forbid-dom-props --
          Use custom `spinner` class specified in initial HTML document. 
          This way we avoid having different styles 
          based on theme for loading spinner, since it's not wanted.
           */}
          <svg className="spinner" viewBox="0 0 50 50">
            <circle cx="25" cy="25" r="20" fill="none" strokeWidth="2px" />
          </svg>
        </HStack>
      </Box>
    </HStack>
  );
}
