import {AnimatePresence, motion, MotionStyle} from 'framer-motion';
import {Box, Heading, Hide, HStack, Show, Space, ThemeZIndexPath} from 'platform/foundation';
import {css, useTheme} from 'styled-components';

import {ReactNode, useEffect} from 'react';

import {isNotNil, not} from 'ramda';

import {RequiredTestIdProps, suffixTestId, TestIdProps} from 'shared';

import {ButtonProps} from '../Button/Button';
import {ButtonGroup} from '../ButtonGroup/ButtonGroup';
import {IconButton} from '../IconButton/IconButton';
import {Pagination, PaginationProps} from '../Pagination/Pagination';
import {Portal} from '../Portal/components/Portal';
import {DialogTransition} from './components/DialogTransition';
import {DialogSize} from './types/DialogSize';
import {ScrollBehavior} from './types/ScrollBehavior';

const DEFAULT_SCROLL_BEHAVIOR = 'inside';

type DialogPaginationProps = Pick<PaginationProps, 'pagesQuantity' | 'onPageChange' | 'page'>;

export interface DialogProps extends TestIdProps {
  isOpen: boolean;
  children: ReactNode;
  title?: string;
  scrollBehavior?: ScrollBehavior;
  size?: DialogSize;
  withAdditionalFooter?: boolean;
  id?: string;
  disableBodyPadding?: boolean;
  hideCloseButton?: boolean;
  /**
   * @about Displays sticky footer with buttons inside
   */
  buttons?: ButtonProps[];
  onClose?: () => void;
  onCloseComplete?: () => void;
  /**
   * If `true`, the modal will return focus to the element that triggered it when it closes.
   * @default true
   */
  returnFocusOnClose?: boolean;
  pagination?: DialogPaginationProps | ReactNode;
  /**
   * @about overrides z-index of the dialog so that it can be displayed above the lightbox
   */
  isInLightbox?: boolean;
}

const DIALOG_HEIGHT_BREAKPOINT_PX = 1000;
export function Dialog(props: DialogProps) {
  const theme = useTheme();
  const isEnlarged = window.innerHeight <= DIALOG_HEIGHT_BREAKPOINT_PX;

  const handleCloseButtonClick = () => {
    props.onClose?.();
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const onKeyDown = (event: Event) => {
    if ('key' in event && event.key === 'Escape' && props.isOpen) {
      event.stopPropagation();

      props.onClose?.();
    }
  };

  const scrollBehavior = props.scrollBehavior ?? DEFAULT_SCROLL_BEHAVIOR;

  return (
    <AnimatePresence onExitComplete={props.onCloseComplete}>
      {props.isOpen && (
        <Portal>
          <StyledOverlay
            zIndex={props.isInLightbox ? 'LIGHTBOX_MODAL' : 'MODAL'}
            data-testid={suffixTestId('dialogOverlay', props)}
          >
            <div
              role="dialog"
              data-testid={suffixTestId('dialogContent', props)}
              css={css`
                display: flex;
                width: 100vw;
                height: 100vh;
                align-items: center;
                justify-content: center;
                position: fixed;
                left: 0;
                top: 0;
                z-index: ${theme.zIndices[props.isInLightbox ? 'LIGHTBOX_MODAL' : 'MODAL']};
                overflow: ${scrollBehavior === 'inside' ? 'hidden' : 'auto'};
                padding: ${scrollBehavior === 'outside' ? `${theme.getSize(8)} 0` : '0'};
              `}
            >
              <DialogTransition
                size={props.size}
                isEnlarged={isEnlarged}
                scrollBehavior={scrollBehavior}
              >
                <Show when={props.title}>
                  <div
                    css={css`
                      display: block;
                      flex: 0 0 auto;
                      box-sizing: border-box;
                      padding: 16px;
                      border-bottom: 1px solid;
                      border-color: ${({theme}) => theme.colors.general.separator};
                    `}
                    data-testid={suffixTestId('dialogHeader', props)}
                  >
                    <HStack spacing={4} justify="space-between" align="center">
                      <Heading size={4}>{props.title}</Heading>
                      <HStack>
                        {typeof props.pagination === 'object' &&
                        isNotNil((props.pagination as DialogPaginationProps).pagesQuantity) ? (
                          <Pagination
                            {...(props.pagination as DialogPaginationProps)}
                            variant="text-only"
                            showPrevButton
                            showNextButton
                            data-testid={suffixTestId('dialogHeaderPagination', props)}
                          />
                        ) : (
                          (props.pagination as ReactNode)
                        )}
                        <Space horizontal={2} />
                        <Hide when={props.hideCloseButton}>
                          <IconButton
                            data-testid={suffixTestId('dialogCloseButton', props)}
                            icon="navigation/close"
                            aria-label="closeDialog"
                            onClick={handleCloseButtonClick}
                          />
                        </Hide>
                      </HStack>
                    </HStack>
                  </div>
                </Show>

                <div
                  css={css`
                    display: block;
                    flex: ${scrollBehavior === 'inside' ? '1 1 auto' : '0 0 auto'};
                    margin-bottom: ${props.withAdditionalFooter ? theme.getSize(16) : 0};
                    padding: ${props.disableBodyPadding ? 0 : theme.getSize(4)};
                    overflow: ${scrollBehavior === 'inside' ? 'auto' : undefined};
                    ${scrollBehavior === 'outside' && `max-height: unset;`}
                  `}
                  data-testid={suffixTestId('dialogBody', props)}
                >
                  {props.children}
                </div>

                <Show when={props.buttons && not(props.withAdditionalFooter)}>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: flex-end;
                      padding: 0;
                    `}
                    data-testid={suffixTestId('dialogFooter', props)}
                  >
                    <Box padding={4}>
                      <ButtonGroup buttons={props.buttons} />
                    </Box>
                  </div>
                </Show>
              </DialogTransition>
            </div>
          </StyledOverlay>
        </Portal>
      )}
    </AnimatePresence>
  );
}

interface StylesOverlayProps extends RequiredTestIdProps {
  zIndex: ThemeZIndexPath;
  children: ReactNode;
}

const StyledOverlay = (props: StylesOverlayProps) => {
  const theme = useTheme();

  return (
    <motion.div
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: theme.zIndices[props.zIndex] as MotionStyle['zIndex'],
        backgroundColor: theme.colors.palettes.neutral[900][20],
        overflow: 'auto',
      }}
      initial="hidden"
      animate="visible"
      exit="hidden"
      data-testid={props['data-testid']}
      variants={{
        visible: {
          opacity: 1,
          transition: {
            duration: 0.3,
            ease: [0.3, 0.1, 0.75, 0.9],
          },
        },
        hidden: {
          opacity: 0,
          transition: {
            duration: 0.1,
            ease: [0.9, 0.75, 0.1, 0.3],
          },
        },
      }}
    >
      {props.children}
    </motion.div>
  );
};
