import {Center, getSize, HStack, Icon, LinkProps, Show, Text} from 'platform/foundation';
import {css} from 'styled-components';
import {match} from 'ts-pattern';

import {isNotNil} from 'ramda-adjunct';

import {suffixTestId} from 'shared';

export function ButtonLink(props: LinkProps) {
  const padding = match(props.size)
    .with('base', () => `${getSize(3)} ${getSize(6)}`)
    .otherwise(() => `${getSize(2)} ${getSize(4)}`);

  return (
    <a
      rel={props.rel}
      target={props.target}
      download={props.download}
      href={props.href ?? '#'}
      onClick={props.isDisabled ? (e) => e.preventDefault() : props.onClick}
      aria-disabled={props.isDisabled}
      css={css`
        display: inline-block;
        color: ${({theme}) => theme.colors.text.white};
        cursor: ${props.isDisabled ? 'auto' : 'pointer'};
        opacity: ${props.isDisabled ? 0.5 : undefined};
        pointer-events: ${props.isDisabled ? 'none' : 'all'};
        padding: ${padding};
        border-radius: ${({theme}) => theme.radii.small};
        background-color: ${({theme}) => theme.colors.accent.base};
        &:hover {
          text-decoration: none;
          background-color: ${({theme}) => theme.colors.accent.dark};
        }
      `}
      data-testid={suffixTestId('buttonLink', props)}
    >
      <HStack spacing={2} align="center">
        <Show when={isNotNil(props.leftIcon)}>
          <Icon size={5} color="general.white" value={props.leftIcon} />
        </Show>
        <Center minHeight={5}>
          <Text color="white" size="small" data-testid={suffixTestId('buttonLinkContent', props)}>
            {props.title}
          </Text>
        </Center>
        <Show when={isNotNil(props.rightIcon)}>
          <Icon size={5} color="general.white" value={props.rightIcon} />
        </Show>
      </HStack>
    </a>
  );
}
