import {POWER_RANGE} from '../consts/POWER_RANGE';
import {YEAR_RANGE} from '../consts/YEAR_RANGE';
import {VehicleData} from '../types/VehicleData';
import {getYearFromDateString} from './getYearFromDateString';

export const getFilters = (vehicleData: VehicleData) => {
  const yearDateString = vehicleData?.firstRegistrationOn ?? vehicleData?.manufacturedOn;
  const year = yearDateString ? getYearFromDateString(yearDateString) : undefined;
  const power = vehicleData?.power;
  const relaxedFilters = {
    model: vehicleData?.modelFamily ? [vehicleData.modelFamily] : undefined,
    'registration-date-from': year ? Number(year) - YEAR_RANGE : undefined,
    'registration-date-to': year ? Number(year) + YEAR_RANGE : undefined,
    sort: 'publish-date',
    limit: 3,
  };

  const strictFilters = {
    ...relaxedFilters,
    transmission: vehicleData?.transmission ? [vehicleData.transmission] : undefined,
    'fuel-type': vehicleData?.fuelType ? [vehicleData.fuelType] : undefined,
    'power-from': power ? Math.max(0, Number(power) - POWER_RANGE) : undefined,
    'power-to': power ? Number(power) + POWER_RANGE : undefined,
    'power-unit': 'kw' as const,
  };
  return {relaxedFilters, strictFilters};
};
