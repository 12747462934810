import {i18n} from '../i18n/i18n';

export const SECTIONS = [
  {
    id: 'SPECIFICATION',
    menuTitle: i18n.t('specificationHeader'),
  },
  {
    id: 'EXTERIOR',
    menuTitle: i18n.t('exteriorHeader'),
  },
  {
    id: 'INTERIOR',
    menuTitle: i18n.t('interiorHeader'),
  },
  {
    id: 'TESTDRIVE',
    menuTitle: i18n.t('testDriveHeader'),
  },
  {
    id: 'WHEELS_AND_TIRES',
    menuTitle: i18n.t('wheelsAndTiresHeader'),
  },
  {
    id: 'MECHANICS',
    menuTitle: i18n.t('mechanicsHeader'),
  },
  {
    id: 'EQUIPMENT',
    menuTitle: i18n.t('featuresHeader'),
  },
  {
    id: 'PHOTODOCUMENTATION',
    menuTitle: i18n.t('photosHeader'),
  },
  {
    id: 'VEHICLE_DOCUMENTATION',
    menuTitle: i18n.t('documentationHeader'),
  },
  {
    id: 'SERVICE_HISTORY',
    menuTitle: i18n.t('serviceHistory'),
  },
  {
    id: 'SIMILAR_CARS',
    menuTitle: i18n.t('similarCars.title'),
  },
] as const;

export type SectionId = (typeof SECTIONS)[number]['id'];
