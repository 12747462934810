import {match} from 'ts-pattern';

import {CustomSectionId} from '../consts/customSectionsId';
import {getWorkspaceFromUri} from './getWorkspaceFromUri';

export function isCustomSectionVisible(sectionId: CustomSectionId) {
  return match<CustomSectionId>(sectionId)
    .with('SIMILAR_CARS', () => {
      const {workspace} = getWorkspaceFromUri();
      return workspace === 'cebia';
    })
    .exhaustive();
}
