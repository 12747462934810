import {Box, Center, HStack, useElementSize} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {SectionId, SECTIONS} from '../../consts/Sections';
import {isSectionActive} from '../Section/utils/getActiveSections';
import {Tab} from '../Tab/Tab';

export interface NavigationTabsProps extends TestIdProps {
  sections: string[];
  hiddenSections?: SectionId[];
}

export function NavigationTabs(props: NavigationTabsProps) {
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [tabsRef, _width, tabsHeight] = useElementSize<HTMLDivElement>();

  useEffect(() => {
    window.onscroll = () => {
      setActiveSection(null);
      document.querySelectorAll('section')?.forEach((section) => {
        if (section.offsetTop === 0) {
          return;
        }
        if (window.scrollY + 2 * (tabsHeight ?? 0) >= section.offsetTop) {
          setActiveSection(section.getAttribute('id'));
        }
      });
    };
  }, [tabsHeight]);

  const navigateToSection = (sectionId: SectionId) => {
    const sectionElement = document.querySelector(`#${sectionId}`);
    if (!sectionElement) {
      return;
    }

    window.scrollTo({
      behavior: 'smooth',
      top:
        sectionElement.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        (tabsHeight ?? 0),
    });
  };

  const sections = SECTIONS.filter(
    (section) =>
      isSectionActive(section.id, props.sections) && !props.hiddenSections?.includes(section.id)
  );

  return (
    <Box
      ref={tabsRef}
      top={0}
      position="sticky"
      overflow="hidden"
      zIndex="NAVIGATION_TABS"
      backgroundColor="general.white"
      data-testid={suffixTestId('navigationTabs', props)}
    >
      <Center justify="center" width="100%">
        <Box
          flexGrow={1}
          maxWidth={400}
          paddingVertical={[2, 4, 6, 6]}
          paddingHorizontal={[2, 10, 10, 20]}
        >
          <Center justify="flex-start" width="100%">
            <HStack maxWidth={400} spacing={1} align="center" justify="flex-start" wrap>
              {sections.map((section) => (
                <Tab
                  key={section.id}
                  title={section.menuTitle}
                  isActive={section.id === activeSection}
                  onClick={() => navigateToSection(section.id)}
                  data-testid={suffixTestId('navigationTab', props)}
                />
              ))}
            </HStack>
          </Center>
        </Box>
      </Center>
    </Box>
  );
}
