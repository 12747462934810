import {AppLanguage} from 'platform/locale';

import {Currency} from '../api/types/carvagoApiTypes';

export const languageToCurrencyMap = {
  cs: 'CZK',
  pl: 'PLN',
  sk: 'EUR',
  de: 'EUR',
  en: 'EUR',
  es: 'EUR',
  it: 'EUR',
  fr: 'EUR',
} as const satisfies Record<AppLanguage, Currency>;
