import {environment} from '@digital-certificate/environment';

import {isNotNil} from 'ramda';

export type Workspace =
  | 'advantagecars'
  | 'advantage-cars-caraudit'
  | 'emil-frey'
  | 'carvago'
  | 'bmw-bonkowscy'
  | 'demo-bmw-bonkowscy'
  | 'autoboss'
  | 'automotive'
  | 'carrara'
  | 'cebia'
  | 'chwalinski'
  | 'ciesiolka'
  | 'citycar'
  | 'demo-autoboss'
  | 'demo-automotive'
  | 'demo-carrara'
  | 'demo-chwalinski'
  | 'demo-ciesiolka'
  | 'demo-citycar'
  | 'demo-ford-pl'
  | 'demo-frankcars'
  | 'demo-germaz'
  | 'demo-globcarsservice'
  | 'demo-mhmotors'
  | 'demo-mirai'
  | 'demo-rencar'
  | 'demo-rewo'
  | 'demo-szumilas'
  | 'demo-wikar'
  | 'frankcars'
  | 'germaz'
  | 'globcarsservice'
  | 'mhmotors'
  | 'mirai'
  | 'rencar'
  | 'rewo'
  | 'szumilas'
  | 'wikar'
  | (string & {});

type GetWorkspaceFromUri = {
  workspace?: Workspace;
  domain: string;
};

const DEFAULT_REVIEW_TENANT = 'carvago';
const REVIEW_APP = 'review';

const POSSIBLE_WORKSPACES = ['localhost', 'localhost:4201', 'dev', 'stage', 'review'];

/**
 * Get OMNETIC workspace from current hostname
 * The list of possible urls looks like this
 * 1. localhost:3000
 * 2. carvago.localhost:3000
 * 3. carvago.dev.teasdev.com
 * 4. develop.review.teasdev.com
 * 5. app.stage.teasdev.com
 */
export const getWorkspaceFromUri = (url?: string): GetWorkspaceFromUri => {
  const [wsp, ...rest] = (url || window.location.host).split('.');

  const isReviewApp = window.location.href.includes(REVIEW_APP);
  const isNoWorkspaceFound = POSSIBLE_WORKSPACES.includes(wsp);

  if (isNotNil(environment.workspace)) {
    return {
      workspace: environment.workspace,
      domain: window.location.host,
    };
  }

  if (isNoWorkspaceFound) {
    return {
      workspace: isReviewApp ? DEFAULT_REVIEW_TENANT : wsp,
      domain: window.location.host,
    };
  }

  const domain = rest.join('.');

  return {
    workspace: isReviewApp ? DEFAULT_REVIEW_TENANT : wsp,
    domain,
  };
};
