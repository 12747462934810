import {Box, Heading, Image, VStack} from 'platform/foundation';

import similarCar from '../../../assets/images/similar_car.png';
import {ButtonLink} from '../../../components/ButtonLink/ButtonLink';
import i18n from '../../../i18n/i18n';

interface MoreSimilarCarsCardProps {
  link: string;
}

export function MoreSimilarCarsCard(props: MoreSimilarCarsCardProps) {
  return (
    <Box
      backgroundColor="accent.lightest"
      borderRadius="small"
      overflow="hidden"
      paddingHorizontal={6}
      paddingVertical={4}
    >
      <VStack height="100%" spacing={8} justify="center" align="center">
        <Image src={similarCar} width={30} height={30} />
        <Heading size={2} align="center">
          {i18n.t('similarCars.more')}
        </Heading>
        <ButtonLink
          title={i18n.t('similarCars.showOffers')}
          href={props.link}
          target="_blank"
          rel="noopener noreferrer"
          rightIcon="navigation/arrow_forward"
        />
      </VStack>
    </Box>
  );
}
