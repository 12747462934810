import {isNilOrEmpty} from 'ramda-adjunct';

import {VehicleAudit} from '../api/digitalCertificateApi';
import {SectionId} from '../consts/Sections';
import {SlideImage} from '../types/SlideImage';
import {SlideVideo} from '../types/SlideVideo';
import {getPhotosByKeys} from '../utils/getPhotosByKeys';
import {getVideosByKeys} from '../utils/getVideosByKeys';

export type PhotoDocumentationDataBundle = {
  photoDocumentationPhotos: SlideImage[];
  photoDocumentationVideos: SlideVideo[];
  sectionConfig: {
    name: SectionId;
    isHidden: boolean;
  };
};
export const useGetDataForPhotoDocumentation = (
  vehicleAudit: VehicleAudit | null | undefined
): PhotoDocumentationDataBundle => {
  const photoDocumentationPhotos = getPhotosByKeys({
    vehicleAudit,
    parentUniqueKey: 'PHOTODOCUMENTATION',
  });
  const photoDocumentationVideos = getVideosByKeys({
    vehicleAudit,
    uniqueKey: 'VIDEOS',
    parentUniqueKey: 'PHOTODOCUMENTATION',
  });

  const shouldBeFeaturesHidden =
    isNilOrEmpty(photoDocumentationPhotos) && isNilOrEmpty(photoDocumentationVideos);

  return {
    photoDocumentationPhotos: photoDocumentationPhotos ?? [],
    photoDocumentationVideos: photoDocumentationVideos ?? [],
    sectionConfig: {
      isHidden: shouldBeFeaturesHidden,
      name: 'PHOTODOCUMENTATION',
    },
  };
};
