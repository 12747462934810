import {LocaleContextValue} from 'platform/locale';

import {isNotNil} from 'ramda';

import {buildArray, EMPTY_PLACEHOLDER, Nullish} from 'shared';

import {Category} from '../api/digitalCertificateApi';
import {DocumentationAttribute} from '../components/DocumentationItem/DocumentationItem';
import i18n from '../i18n/i18n';
import {Documentation} from '../types/Documentation';
import {getValueWithUnit} from './getValueWithUnit';
import {isCategoryEnabledInAudit} from './isCategoryEnabledInAudit';

export interface DocumentationParams {
  technicalCertification: DocumentationAttribute[];
  vehicleUnderFactoryWarranty: DocumentationAttribute[];
  operatingInstructions: {attributes: DocumentationAttribute[]; isEnabledInAudit?: boolean};
  currentMileage: string | null;
}

interface GetDocumentationItemParamsParams {
  documentation: Documentation;
  locale: LocaleContextValue;
  getDate: (value: string | number | Nullish) => string | null;
  vehicleAuditCategories: Category[];
}

export const getDocumentationItemParams = ({
  documentation,
  locale,
  getDate,
  vehicleAuditCategories,
}: GetDocumentationItemParamsParams): DocumentationParams | null => {
  if (!documentation) {
    return null;
  }

  const currentMileage = getValueWithUnit({
    auditValue: documentation.currentMileage?.value,
    localeConfig: locale.localeConfig,
    decimals: 0,
    defaultUnitTranslate: i18n.t('unitKilometers'),
  });

  const technicalCertification = buildArray<DocumentationAttribute>([])
    .add({
      label: i18n.t('status'),
      value: documentation.technicalCert?.status ?? EMPTY_PLACEHOLDER,
    })
    .when(isNotNil(documentation.technicalCert?.numberOfPartsOfRegistrationCertificate), {
      label: i18n.t('numberOfParts'),
      value: documentation.technicalCert?.numberOfPartsOfRegistrationCertificate,
    })
    .when(isNotNil(documentation.technicalCert?.numberOfWwnersInTC), {
      label: i18n.t('numberOfWwnersInTC'),
      value: documentation.technicalCert?.numberOfWwnersInTC,
    })
    .when(isNotNil(documentation.technicalCert?.countryOfFirstRegistration), {
      label: i18n.t('countryOfFirstRegistration'),
      value: documentation.technicalCert?.countryOfFirstRegistration,
    });

  const vehicleUnderFactoryWarranty = buildArray<DocumentationAttribute>([])
    .add({
      label: i18n.t('status'),
      value: documentation.vehicleUnderFactoryWarranty?.status ?? EMPTY_PLACEHOLDER,
    })
    .when(isNotNil(documentation.vehicleUnderFactoryWarranty?.starDate), {
      label: i18n.t('warrantyStartDate'),
      value: getDate(documentation.vehicleUnderFactoryWarranty?.starDate),
    })
    .when(isNotNil(documentation.vehicleUnderFactoryWarranty?.endDate), {
      label: i18n.t('warrantyEndDate'),
      value: getDate(documentation.vehicleUnderFactoryWarranty?.endDate),
    })
    .when(isNotNil(documentation.vehicleUnderFactoryWarranty?.raidUntilEndOfWarranty), {
      label: i18n.t('raidUntilEndOfWarranty'),
      value: getValueWithUnit({
        auditValue: documentation.vehicleUnderFactoryWarranty?.raidUntilEndOfWarranty,
        localeConfig: locale.localeConfig,
        decimals: 0,
        defaultUnitTranslate: i18n.t('unitKilometers'),
      }),
    });

  const operatingInstructions = buildArray<DocumentationAttribute>([])
    .add({
      label: i18n.t('status'),
      value: documentation.operatingInstructions ?? EMPTY_PLACEHOLDER,
    })
    .when(isNotNil(documentation.languageOfInstructions), {
      label: i18n.t('languageOfInstructions'),
      value: documentation.languageOfInstructions,
    });

  const isEnabledInAudit = isCategoryEnabledInAudit({
    categories: vehicleAuditCategories,
    parentUniqueKey: 'VEHICLE_DOCUMENTATION',
    uniqueKey: 'OPERATING_INSTRUCTIONS',
  });

  return {
    currentMileage,
    technicalCertification,
    vehicleUnderFactoryWarranty,
    operatingInstructions: {
      attributes: operatingInstructions,
      isEnabledInAudit,
    },
  };
};
