import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';

import {carvagoApi} from './api/carvagoApi';
import {digitalCertificateApi} from './api/digitalCertificateApi';

export const store = configureStore({
  reducer: {
    [digitalCertificateApi.reducerPath]: digitalCertificateApi.reducer,
    [carvagoApi.reducerPath]: carvagoApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(digitalCertificateApi.middleware, carvagoApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
