import {Image, ImageProps} from 'platform/foundation';

import {TestIdProps} from 'shared';

import {useThemeConfig} from '../../hooks/useThemeConfig';

interface BrandLogoProps extends TestIdProps, Omit<ImageProps, 'src'> {}

const DEFAULT_LOGO_PATH = '../../assets/images/logo.svg';
const ALT_TEXT = {
  DIGITAL_CERTIFICATE: 'Digital Certificate',
  COMPANY_LOGO: 'Company Logo',
};

export function BrandLogo(props: BrandLogoProps) {
  const {brandLogo} = useThemeConfig();

  const hasBrandLogo = typeof brandLogo === 'string';

  const logoSrc = hasBrandLogo ? brandLogo : DEFAULT_LOGO_PATH;
  const alt = hasBrandLogo ? ALT_TEXT.COMPANY_LOGO : ALT_TEXT.DIGITAL_CERTIFICATE;

  return (
    <Image
      alt={alt}
      isLazy
      width="auto"
      src={logoSrc}
      {...props}
      data-testid={props['data-testid']}
    />
  );
}
