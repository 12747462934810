import {LocaleContextValue} from 'platform/locale';

import {VehicleAudit} from '../api/digitalCertificateApi';
import {getSlideshowByUniqueKey} from '../components/Slideshow/utils/getSlideshowByUniqueKey';
import {SectionId} from '../consts/Sections';
import {Mechanics} from '../types/Mechanics';
import {SlideImage} from '../types/SlideImage';
import {getMechanicFeatures} from '../utils/getMechanicFeatures';
import {getMechanics} from '../utils/getMechanics';

export type MechanicsDataBundle = {
  features: ReturnType<typeof getMechanicFeatures>;
  mechanics: Mechanics;
  mechanicSlideshow: {title: string; comment: string | null; slides: SlideImage[] | null}[] | null;
  sectionConfig: {
    name: SectionId;
    isHidden: boolean;
  };
};
export const useGetDataForMechanics = (
  vehicleAudit: VehicleAudit | null | undefined,
  locale: LocaleContextValue
): MechanicsDataBundle => {
  const mechanics = getMechanics({vehicleAudit, language: locale.language});
  const features = getMechanicFeatures(mechanics);

  const mechanicSlideshow = getSlideshowByUniqueKey({
    vehicleAudit,
    language: locale.language,
    parentUniqueKey: 'MECHANICS',
  });

  const shouldBeDataHidden = !features?.allFeatures.some((item) => item.variant !== 'neutral');

  return {
    mechanics,
    features,
    mechanicSlideshow,
    sectionConfig: {isHidden: shouldBeDataHidden, name: 'MECHANICS'},
  };
};
