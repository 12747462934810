import {
  Box,
  CSSDimension,
  Heading,
  HStack,
  Image,
  Show,
  Stack,
  useElementSize,
} from 'platform/foundation';
import styled from 'styled-components';
import {match, Pattern} from 'ts-pattern';

import {always} from 'ramda';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {BrandLogo} from '../../../components/BrandLogo/BrandLogo';
import {getWorkspaceCustomization} from '../../../utils/getWorkspaceCustomization';

export interface CertificateBadgePrintProps extends TestIdProps {
  makeModel: string | Nullish;
  specification: string | Nullish;
}

export function CertificateBadgePrint(props: CertificateBadgePrintProps) {
  const [containerRef, width] = useElementSize<HTMLDivElement>();
  const {isDigitalCertificateLogoShown} = getWorkspaceCustomization();

  const top = match<number | null, CSSDimension>(width)
    .with(Pattern.number.between(0, 680), always('-20%'))
    .with(Pattern.number.between(680, 945), always('-50%'))
    .with(Pattern.number.between(945, 1290), always('-140%'))
    .otherwise(always('-140%'));

  const left = match<number | null, CSSDimension>(width)
    .with(Pattern.number.between(0, 680), always('15%'))
    .with(Pattern.number.between(680, 945), always('30%'))
    .with(Pattern.number.between(945, 1290), always('50%'))
    .otherwise(always('40%'));

  const right = match<number | null, CSSDimension>(width)
    .with(Pattern.number.between(0, 680), always('-30%'))
    .otherwise(always('0rem'));

  return (
    <Box
      ref={containerRef}
      flexGrow={0}
      flexShrink={0}
      overflow="hidden"
      position="relative"
      borderRadius="small"
      paddingVertical={[3, 4, 4, 4]}
      paddingHorizontal={[3, 4, 4, 6]}
      data-testid={suffixTestId('certificateBadge', props)}
    >
      <GradientBackground />
      <Box position="absolute" overflow="hidden" top={top} left={left} right={right} bottom={0}>
        <HStack height="100%" align="flex-end" justify="flex-end">
          <Image
            maxWidth={230}
            fit="cover"
            width="100%"
            height="100%"
            src="../../assets/images/watermark.svg"
          />
        </HStack>
      </Box>
      <Box position="relative">
        <Stack spacing={10} direction="row" align="flex-start" justify="flex-start">
          <Show when={isDigitalCertificateLogoShown}>
            <BrandLogo height={6} />
          </Show>
          <Stack spacing={4} direction="row" align="center">
            <Heading size={2}>{props.makeModel}</Heading>
            <Heading size={4} color="secondary" alternative>
              {props.specification}
            </Heading>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

// eslint-disable-next-line eag/no-css-property
const GradientBackground = styled.div`
  position: absolute;
  pointer-events: none;
  inset: 0;
  background: linear-gradient(90deg, #fcf2fc 0%, #f9f8f2 52.08%, #f5f0ff 100%);
`;
