import {FoundationProvider} from 'platform/foundation';
import {Currency, LocaleConfig, LocaleProvider} from 'platform/locale';

import {ReactNode, useMemo} from 'react';

import {mergeDeepRight} from 'ramda';

import {ResetCss} from '../../constants/resetCss';
import {componentsTheme} from '../../utils/componentsTheme';
import {DialogsProvider} from '../Dialog/components/DialogsProvider';
import {DropdownProvider} from '../Dropdown/components/DropdownProvider';
import {NotificationsProvider} from '../Notification/components/NotificationsProvider';
import {
  TranslationProvider,
  TranslationInterpreter,
} from '../TranslationProvider/TranslationProvider';

type ComponentsProviderProps = {
  theme?: Record<string, unknown>;
  children?: ReactNode;
  t?: TranslationInterpreter;
  localeConfig?: LocaleConfig;
  language: string;
  currencies?: Currency[];
};

export function ComponentsProvider(props: ComponentsProviderProps) {
  const mergedTheme = useMemo(
    () => mergeDeepRight(componentsTheme, props.theme ?? {}),
    [props.theme]
  );

  return (
    <FoundationProvider theme={mergedTheme}>
      <ResetCss />
      <TranslationProvider t={props.t}>
        <LocaleProvider
          currencies={props.currencies}
          language={props.language}
          config={props.localeConfig}
        >
          <NotificationsProvider>
            <DialogsProvider>
              <DropdownProvider>{props.children}</DropdownProvider>
            </DialogsProvider>
          </NotificationsProvider>
        </LocaleProvider>
      </TranslationProvider>
    </FoundationProvider>
  );
}
